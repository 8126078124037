import React, { useState } from "react";
import CreateUser from "./components/CreateUser";
import notify from "utils/toastMsg";
import DeleteRecordModal from "./components/DeleteRecordModal";
import deleteNotifyMeById from "services/deleteNotifyMeById";
import deleteNotifyMeDashboardById from "services/deleteNotifyMeDashboardById";
import UsersTable from "./components/UsersTable";
import NotifyMeTable from "./components/NotifyMeTable";
import NotifyMeLandingPageTable from "./components/NotifyMeLandingPageTable";
import SiemensUsersTable from "./components/SiemensUsersTable";
import Authentication from "services/Authentication";

const auth = new Authentication();

const Admin = () => {
  const jwtPayload = auth.getAccessTokenPayload();
  // const [sessionsToFetch, setSessionsToFetch] = useState([]);
  const [idToDelete, setIdToDelete] = useState(null);
  const [deleteFunctionToUse, setDeleteFunctionToUse] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [refreshKey, setRefreshKey] = React.useState(0);

  const handleDeleteRecord = async (idToDelete, deleteFunctionToUse) => {
    try {
      if (deleteFunctionToUse === "landingPage") {
        await deleteNotifyMeById({ idToDelete });
        setRefreshKey((oldKey) => oldKey + 1);
      } else if (deleteFunctionToUse === "dashboard") {
        await deleteNotifyMeDashboardById({ idToDelete });
        setRefreshKey((oldKey) => oldKey + 1);
      }
      notify("Record Deleted Successfully. ✅ ");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      notify("An error occurred while deleting the data. ❌");
    }
  };

  return (
    <div style={{ marginTop: 150 }}>
      <div
        className="row cardss"
        style={{ display: "flex", justifyContent: "center", marginBottom: 120 }}
      >
        <div className="col-xl-9">
          <h3>Notify Me Web Site</h3>
          <NotifyMeLandingPageTable
            setIdToDelete={setIdToDelete}
            setDeleteFunctionToUse={setDeleteFunctionToUse}
            setShowDeleteDialog={setShowDeleteDialog}
            refreshKey={refreshKey}
          />
        </div>
        <div className="col-xl-9">
          <h3>Notify Me Dashboard</h3>
          <NotifyMeTable
            setIdToDelete={setIdToDelete}
            setDeleteFunctionToUse={setDeleteFunctionToUse}
            setShowDeleteDialog={setShowDeleteDialog}
            refreshKey={refreshKey}
          />
        </div>
        <div className="col-xl-9">
          <h3>All Users</h3>
          <UsersTable />
        </div>
        <div className="col-xl-9">
          <h3>Users From Siemens Registration</h3>
          <SiemensUsersTable organizationId={jwtPayload.organizationId} />
        </div>
      </div>
      <div
        className="modal fade bs-example-modal-sm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                Add New user
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <CreateUser setRefreshKey={setRefreshKey} />
            </div>
          </div>
        </div>
      </div>
      {showDeleteDialog && (
        <DeleteRecordModal
          handleDeleteRecord={handleDeleteRecord}
          setShowDeleteDialog={setShowDeleteDialog}
          deleteFunctionToUse={deleteFunctionToUse}
          idToDelete={idToDelete}
        />
      )}
    </div>
  );
};

export default Admin;
