import http from "utils/http";
import { users } from "constants/endpoints";

const getSiemensRegistrations = ({ organizationId, queryParams }) => {
  const ENDPOINT = users.getSiemensRegistrations({
    organizationId,
    queryParams,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getSiemensRegistrations;
