import { XestTable } from "components/CustomComponents";
import React from "react";
import getSiemensRegistrations from "services/getSiemensRegistrations";

const SiemensUsersTable = ({ organizationId }) => {
  const columns = [
    {
      dataIndex: "first_name",
      title: "First Name",
      filterType: {
        dbCol: "users.first_name",
        type: "string",
      },
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "last_name",
      title: "Last Name",
      filterType: {
        dbCol: "users.last_name",
        type: "string",
      },
    },
    {
      dataIndex: "email",
      title: "Email",
      filterType: {
        dbCol: "users.email",
        type: "string",
      },
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "organization_name",
      title: "Organization Name",
      filterType: {
        dbCol: "organizations.organization_name",
        type: "string",
      },
    },
    {
      dataIndex: "subscribed_product",
      title: "Subscribed Product",
      filterType: {
        dbCol: "subscribed_product",
        type: "string",
      },
    },
    {
      dataIndex: "subscription_status",
      title: "Subscription Status",
      filterType: {
        dbCol: "subscription_status",
        type: "string",
      },
    },
    {
      dataIndex: "subscription_expiry_date",
      title: "Subscription Expire At",
      filterType: {
        dbCol: "subscription_expiry_date",
        type: "date",
      },
    },
  ];

  const params = {
    apiCallFn: async (queryParams) => {
      let data, error;
      await getSiemensRegistrations({ organizationId, queryParams })
        .then(async (res) => {
          data = res.data;
        })
        .catch((err) => (error = err));

      return {
        data,
        error,
      };
    },
    deps: [1],
    initialPageSize: 10,
    initialFilters: [],
    initialSortCriteria: null,
    config: {
      filtersKeyGen() {
        return "dt--siemens-users";
      },
      tableNum: 4,
    },
  };
  return <XestTable columns={columns} params={params} />;
};

export default SiemensUsersTable;
